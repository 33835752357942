import React from 'react'

import styles from './ingredient-score.module.css'

function IngredientScore (props) {
    let {score, size} = props
    return (
        <span className={score>7 ? styles.red : score > 3 ? styles.yellow : styles.green} styles={
            (size && size == "small" && {"width":"50px", "height":"50px","line-height":"3rem","font-size":"1rem"})
            || {"width":"80px", "height":"80px","line-height":"5rem","font-size":"3rem"}
    }>{score}</span>
    );
}

export default IngredientScore
